import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const StyledTitle = styled(Modal.Title)`
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
`;

const StyledCloseButton = styled(Button)`
    background-color: #fdba01;
    max-width: fit-content;
    padding: 10px 30px !important;
    border-radius: 25px;
    font-family: Open Sans;
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    border: none;

    &:hover {
        background-color: #fdba01;
        color: rgba(0, 0, 0, 0.8);
    }
`;

const ErrorModal = ({ show, handleClose, errorMessage }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <StyledTitle>Unable to submit form</StyledTitle>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    {Object.keys(errorMessage).map((key, index) => (
                        <li key={index}>{errorMessage[key]}</li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <StyledCloseButton variant="secondary" onClick={handleClose}>
                    Close
                </StyledCloseButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
