const getTransactionStatus = async (_data) => {
    try {
        const url = window.location.href;
        const isLocalhost = url.includes('localhost') || url.includes('pa365.dev.bpimsapps.com');
        const uat = url.includes('pa365.uat.bpimsapps.com'); 
        const prod = url.includes('pa365.bpims.com');
        const [, stage, , flag] = url.split('.');

        const endpoint = isLocalhost
        ? "https://partners-api.dev.bpimsapps.com/payment/recurring/status"
        : uat
            ? "https://partners-api.uat.bpimsapps.com/payment/recurring/status"
            : prod
                ? `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${flag ? '' : '/partners'}/payment/recurring/status`
                : `https://partners-api.${flag ? `${stage}.` : ''}bpimsapps.com/payment/recurring/status`;

        const response = await fetch(endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('getTransactionStatus api error', error);
        throw error;
    }
};

export default getTransactionStatus;
