import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import AppNavbar from './components/AppNavBar';
import Landing from './components/Landing';
import Form from './components/Form';
import Claim from './components/Claim';
import Footer from './components/Footer';

import submitForm from './service/submitForm';
import getTransactionStatus from './service/getTransactionStatus';

import getPlans from './service/getPlans';
import getRestrictions from './service/getRestrictions';
import validateReferrerCode from './service/validateReferrerCode';
import getAddress from './service/getAddress';

import './App.css';

const MainContainer = styled(Container)`
    &&& {
        position: relative;
        overflow-x: clip;
        height: fit-content;
    }
`;

const Loading = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
`;

const StyledAlert = styled(Alert)`
    z-index: 5000;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const VerticalLineContainer = styled(Row)`
    position: relative;

    @media (max-width: 600px) {
        display: none !important;
    }
`;

const VerticalLineWrapper = styled.div`
    min-height: 1px;
    background: #ffeac4;
`;

const VerticalLineNarrow = styled.div`
    position: absolute;
    min-width: 150px;
    background: #d5b178;
    min-height: 300vh;
    bottom: 0;
    left: 68%;
    z-index: 3;
`;

const VerticalLine = styled.div`
    position: absolute;
    min-width: 550px;
    background: #ffdfab;
    min-height: 300vh;
    bottom: 0;
    left: 68%;
    z-index: 2;
`;

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamValue = queryParams.get('stage');

    const [loading, setLoading] = React.useState(false);
    const [loadingPlans, setLoadingPlans] = React.useState(false);
    const [allowReload, setAllowReload] = React.useState(false);
    const [selectedPlan, setSelectedPlan] = React.useState({});
    const [restrictions, setRestrictions] = React.useState({});
    const [form, setForm] = React.useState({
        autoRenew: true
    });
    const [address, setAddress] = React.useState({});

    React.useEffect(() => {
        return () => {
            setForm(() => ({
                autoRenew: true
            }));
        };
    }, []);

    React.useEffect(() => {
        if (queryParamValue && queryParamValue != 's' && queryParamValue != 'f') {
            setLoading(true);
            handleTransactionStatus(queryParamValue, () =>
                handleTransactionStatus(queryParamValue, () => {
                    setAllowReload(() => true);
                    handleTransactionStatus(queryParamValue, () =>
                        handleTransactionStatus(queryParamValue, () =>
                            handleTransactionStatus(queryParamValue, () => {
                                setTimeout(() => window.location.reload(), 5000);
                            })
                        )
                    );
                })
            );
        }
    }, []);

    React.useEffect(() => {
        handleRestrictions();
        handlePlans();
        handleAddress();
    }, []);

    const handlePlans = async () => {
        if (loadingPlans) return;

        setLoadingPlans(true);
        try {
            const _plans = await getPlans();
            const { plan } = _plans.data;
            const responsePlans = plan.slice();
            setSelectedPlan(responsePlans[0] ?? null);
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            setLoadingPlans(false);
            const url = new URL(window.location.href);
            const hash = url.hash;
            if (hash && hash === '#form') {
                const formElement = document.getElementById('form-section');
                if (formElement) {
                    formElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const handleRestrictions = async () => {
        try {
            const _restrictions = await getRestrictions();
            setRestrictions((p) => ({ ...p, ..._restrictions }));
        } catch (error) {
            console.error('Error fetching restrictions:', error);
        }
    };

    const handleAddress = async () => {
        try {
            const _address = await getAddress();
            const { province, city, barangay } = _address;
            console.log('address', _address);

            const mappedProvince = province.map((item) => {
                return { ...item, key: item.provinceName, value: item.provinceName };
            });

            const mappedCity = city.map((item) => {
                return { ...item, key: item.cityName, value: item.cityName };
            });

            const mappedBarangay = barangay.map((item) => {
                return { ...item, key: item.postalDesc, value: item.postalDesc };
            });

            setAddress({
                province: mappedProvince,
                city: mappedCity,
                barangay: mappedBarangay
            });
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setLoadingPlans(false);
        }
    };

    const validateReffererCode = async (params) => {
        try {
            const _validateRes = await validateReferrerCode(params);

            return _validateRes;
        } catch (error) {
            console.error('Error validating refferer code:', error);
        }
    };

    const handleSubmit = async () => {
        if (loading) return;
        document.body.style.overflow = 'hidden';

        setLoading(true);
        try {
            const res = await submitForm({ ...form, planId: selectedPlan?.id });
            const { Location } = res;
            if (Location) {
                window.location.href = Location;
            }
            setSelectedPlan({});
            setForm({});
        } catch (error) {
            console.error('API Call error', error);
        } finally {
            setLoading(false);
            document.body.style.overflow = '';
        }
    };

    const handleTransactionStatus = async (tranxRef, callback = () => {}) => {
        try {
            document.body.style.overflow = 'hidden';
            const res = await getTransactionStatus({ tranxRef });
            const { policyStatus } = res;

            if (['s', 'f'].includes(policyStatus)) window.location.href = `/?stage=${policyStatus}`;
            if (!callback) return;
            callback(); // retry if it fails
        } catch (error) {
            console.error('API handleTransactionStatus Call error', error);
        }
    };

    return (
        <MainContainer className="p-0" fluid>
            {loading && (
                <Loading>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Loading>
            )}
            {allowReload && (
                <StyledAlert variant="warning">
                    It seems your transaction is taking longer than expected. We will retry in a few seconds.
                </StyledAlert>
            )}
            <Row>
                <AppNavbar />
            </Row>
            <Container fluid className="p-0">
                <Row>
                    <Landing stage={queryParamValue} />
                </Row>
                {!queryParamValue && (
                    <Row>
                        <Form
                            data={form}
                            handleData={setForm}
                            handleSubmit={handleSubmit}
                            planObject={selectedPlan}
                            formLoading={loading}
                            formRestrictions={restrictions}
                            handleReferrerValidate={validateReffererCode}
                            address={address}
                            setAddress={setAddress}
                        />
                    </Row>
                )}

                <VerticalLineContainer>
                    <VerticalLineWrapper>
                        <VerticalLineNarrow />
                        <VerticalLine />
                    </VerticalLineWrapper>
                </VerticalLineContainer>

                <Row>
                    <Claim />
                </Row>
                <Row>
                    <Footer />
                </Row>
            </Container>
        </MainContainer>
    );
}

export default App;
