import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

const RootContainer = styled(Container)`
    background-color: #ffeac4;
`;

const LandingContainer = styled(Container)`
    &&& {
        padding: 0 10%;
    }

    * {
        font-family: 'Bakbak One', sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`;

const StyledDefaulImageContainer = styled(Col)`
    display: flex;
    justify-content: center;
    padding: 0;
    margin-left: 10%;
    z-index: 4;

    @media (max-width: 600px) {
        margin: 0 !important;
    }
`;

const StyledDefaultContentContainer = styled(Col)`
    padding: 0;
    margin-left: -14%;
    padding-top: 4%;
    margin-bottom: 30px;
    position: relative;

    @media (max-width: 600px) {
        margin: 0 !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
`;

const StyledDefaultH1 = styled.h1`
    font-size: 76px;
    font-weight: bold;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    z-index: 4;

    @media (max-width: 600px) {
        font-size: 48px !important;
    }
`;

const StyledFiller = styled.span`
    color: transparent;
    user-select: none;
`;

const StyledDefaultVerticalText = styled.span`
    display: inline-block;
    rotate: 270deg;
    font-size: 38px;
    padding-left: 10px;
    padding-top: 20px;
    font-weight: bold;

    @media (max-width: 600px) {
        padding-left: 0 !important;
        padding-top: 5px;
    }
`;

const StyledDefaultSubStack = styled(Stack)`
    text-align: right;
    width: fit-content;
    padding-left: 5%;

    @media (max-width: 600px) {
        margin-top: 30px !important;
    }
`;

const StyledDefaultSubText = styled.h6`
    font-family: Open Sans;
    font-weight: 600;
    z-index: 4;
    position: relative;

    @media (max-width: 600px) {
        text-align: left !important;
    }
`;

const StyledDefaultNarrowVerticalLine = styled.div`
    height: 150%;
    position: absolute;
    bottom: 0;
    width: 37px;
    margin-left: 46px;
    background: #ffdfab;
    z-index: 1;

    @media (max-width: 600px) {
        display: none !important;
    }
`;

const DefaultLandingComponent = () => {
    return (
        <LandingContainer fluid>
            <Row fluid lg={2} xs={1} className="p-0">
                <StyledDefaulImageContainer className="d-block">
                    <Image src={require('../assets/images/pa365.png')} fluid />
                </StyledDefaulImageContainer>
                <StyledDefaultContentContainer>
                    <Stack>
                        <StyledDefaultH1>Php 1 Million</StyledDefaultH1>
                        <StyledDefaultH1>
                            <StyledFiller>&nbsp;</StyledFiller>
                            <StyledDefaultVerticalText>for</StyledDefaultVerticalText>
                            <StyledFiller>'</StyledFiller>Accidents
                        </StyledDefaultH1>
                    </Stack>

                    <StyledDefaultSubStack className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                        <StyledDefaultSubText>
                            Accidents happen everyday - at home, at work, in the
                        </StyledDefaultSubText>
                        <StyledDefaultSubText
                            style={{
                                marginBottom: '30px',
                                fontFamily: 'Open Sans',
                                fontWeight: 600,
                                zIndex: 4
                            }}
                        >
                            country, abroad, in broad daylight and at night.
                        </StyledDefaultSubText>
                        <StyledDefaultSubText>Good thing you can be prepared for the costs</StyledDefaultSubText>
                        <StyledDefaultSubText>of accidents for only one (1) peso a day!</StyledDefaultSubText>
                    </StyledDefaultSubStack>

                    <StyledDefaultSubStack className="d-lg-none d-xl-none d-md-none d-sm-none d-block ">
                        <StyledDefaultSubText>
                            Accidents happen everyday - at home, at work, in the country, abroad, in broad daylight and
                            at night.
                        </StyledDefaultSubText>
                        <StyledDefaultSubText>
                            Good thing you can be prepared for the costs of accidents for only one (1) peso a day!
                        </StyledDefaultSubText>
                    </StyledDefaultSubStack>

                    <StyledDefaultNarrowVerticalLine />
                </StyledDefaultContentContainer>
            </Row>
        </LandingContainer>
    );
};

const LandingStagedContainer = styled(Container)`
    &&& {
        padding: 0 10%;
        padding-bottom: 50px;
    }

    * {
        font-family: 'Bakbak One', sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`;

const StyledStagedImageContainer = styled(Col)`
    display: flex;
    justify-content: center;
    padding: 0;
    z-index: 2;
`;

const StyledStagedImage = styled(Image)`
    min-height: 500px;

    @media (max-width: 600px) {
        min-height: unset !important;
    }
`;

const StyledStagedContentContainer = styled(Col)`
    padding: 0;
    padding-top: 4%;
    margin-bottom: 30px;
    position: relative;
    flex-shrink: 1;
    width: fit-content;
    padding-left: 10%;

    @media (max-width: 600px) {
        padding: 4% 30px 0 30px !important;
    }
`;

const StyledStagedH1 = styled.h1`
    font-size: 56px;
    font-weight: bold;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    letter-spacing: 0.7px;
`;

const StyledStackWrapper = styled.div`
    padding: 30px 10px;
`;

const StyledStagedSubStack = styled(Stack)`
    width: fit-content;
    margin-bottom: 20px;
`;

const StyledStagedSubText = styled.h6`
    font-family: Open Sans;
    font-weight: 600;
    z-index: 2;
`;

const StyledStagedH1Secondary = styled.h1`
    font-size: 48px;
    font-weight: bolder;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    font-family: Open Sans;
`;

const StyledStackWrapperSecondary = styled.div`
    padding: 10px 0;
`;

const StyledStagedSubTextSecondary = styled.h6`
    font-family: Open Sans;
    font-weight: 600;
    z-index: 2;
    margin: 0;
`;

const StyledStagedButton = styled(Button)`
    background-color: #fdba01;
    max-width: fit-content;
    padding: 10px 30px;
    border-radius: 25px;
    font-family: Open Sans;
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    border: none;

    &:hover {
        background-color: #fdba01;
        color: rgba(0, 0, 0, 0.8);
    }
`;

const StyledStagedRow = styled(Row)`
    padding: 0 50px !important;

    @media (max-width: 600px) {
        padding: 0 !important;
    }
`;

const SuccessLandingComponent = () => {
    return (
        <LandingStagedContainer fluid>
            <Row fluid className="p-0 justify-content-center">
                <StyledStagedImageContainer className="d-lg-none d-xl-none d-md-none d-sm-none d-block">
                    <StyledStagedImage src={require('../assets/images/success.png')} fluid />
                </StyledStagedImageContainer>
            </Row>
            <Row fluid lg={2} xs={1} className="p-0 justify-content-center">
                <StyledStagedContentContainer>
                    <Stack className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                        <StyledStagedH1>Catching you</StyledStagedH1>
                        <StyledStagedH1>if you fall!</StyledStagedH1>
                    </Stack>

                    <Stack className="d-lg-none d-xl-none d-md-none d-sm-none d-block">
                        <StyledStagedH1>Catching you if you fall!</StyledStagedH1>
                    </Stack>

                    <StyledStackWrapper className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                        <StyledStagedSubStack>
                            <StyledStagedSubText>Congratulations!</StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>You have successfully availed BPI MS PA365,</StyledStagedSubText>
                            <StyledStagedSubText>which gives you access to up to Php1 Million</StyledStagedSubText>
                            <StyledStagedSubText>in case of accidents.</StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>You will receive a copy of your confirmation of</StyledStagedSubText>
                            <StyledStagedSubText>insurance coverage through the email you</StyledStagedSubText>
                            <StyledStagedSubText>have provided.</StyledStagedSubText>
                        </StyledStagedSubStack>
                    </StyledStackWrapper>

                    <StyledStackWrapper className="d-lg-none d-xl-none d-md-none d-sm-none d-block">
                        <StyledStagedSubStack>
                            <StyledStagedSubText>Congratulations!</StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>
                                You have successfully availed BPI MS PA365, which gives you access to up to Php1 Million
                                in case of accidents.
                            </StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>
                                You will receive a copy of your confirmation of insurance coverage through the email you
                                have provided.
                            </StyledStagedSubText>
                        </StyledStagedSubStack>
                    </StyledStackWrapper>
                </StyledStagedContentContainer>
                <StyledStagedImageContainer className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                    <StyledStagedImage src={require('../assets/images/success.png')} fluid />
                </StyledStagedImageContainer>
            </Row>

            <StyledStagedRow fluid xs={1} className="p-0">
                <StyledStagedContentContainer>
                    <Stack>
                        <StyledStagedH1Secondary>BPI MS Express Protect</StyledStagedH1Secondary>
                    </Stack>

                    <StyledStackWrapperSecondary>
                        <StyledStagedSubStack>
                            <StyledStagedSubTextSecondary>
                                Protect your dreams and investments by insuring your
                            </StyledStagedSubTextSecondary>
                            <StyledStagedSubTextSecondary>
                                car, home, family, and health. Visit BPI MS Express Protect.
                            </StyledStagedSubTextSecondary>
                        </StyledStagedSubStack>
                    </StyledStackWrapperSecondary>

                    <Stack>
                        <StyledStagedButton onClick={() => window.open('https://eprotect.bpims.com/', 'bpims')}>
                            BPI MS EXPRESS PROTECT
                        </StyledStagedButton>
                    </Stack>
                </StyledStagedContentContainer>
            </StyledStagedRow>
        </LandingStagedContainer>
    );
};

const StyledStagedStackWrapper = styled(StyledStackWrapper)`
    padding-bottom: 10px !important;
`;

const StyledStagedFailedButton = styled(StyledStagedButton)`
    padding: 10px 80px !important;

    @media (max-width: 600px) {
        margin: 0 auto !important;
    }
`;

const FailedLandingComponent = () => {
    return (
        <LandingStagedContainer fluid>
            <Row fluid className="p-0 justify-content-center">
                <StyledStagedImageContainer className="d-lg-none d-xl-none d-md-none d-sm-none d-block">
                    <StyledStagedImage src={require('../assets/images/failed.png')} fluid />
                </StyledStagedImageContainer>
            </Row>
            <Row fluid lg={2} xs={1} className="p-0 justify-content-center">
                <StyledStagedContentContainer>
                    <Stack>
                        <StyledStagedH1>Ooops!</StyledStagedH1>
                    </Stack>

                    <StyledStagedStackWrapper className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                        <StyledStagedSubStack>
                            <StyledStagedSubText>We're having some trouble debiting your</StyledStagedSubText>
                            <StyledStagedSubText>account or charging your credit card.</StyledStagedSubText>
                            <StyledStagedSubText>Your BPI MS PA365 is not yet active.</StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>You can try again using a different</StyledStagedSubText>
                            <StyledStagedSubText>account or payment method</StyledStagedSubText>
                        </StyledStagedSubStack>
                    </StyledStagedStackWrapper>

                    <StyledStackWrapper className="d-lg-none d-xl-none d-md-none d-sm-none d-block">
                        <StyledStagedSubStack>
                            <StyledStagedSubText>
                                We're having some trouble debiting your account or charging your credit card. Your BPI
                                MS PA365 is not yet active.
                            </StyledStagedSubText>
                        </StyledStagedSubStack>

                        <StyledStagedSubStack>
                            <StyledStagedSubText>
                                You can try again using a different account or payment method
                            </StyledStagedSubText>
                        </StyledStagedSubStack>
                    </StyledStackWrapper>

                    <Stack>
                        <StyledStagedFailedButton
                            onClick={() => {
                                const urlWithoutQueryParams = window.location.href.split('?')[0];
                                window.history.pushState({}, '', `${urlWithoutQueryParams}#form`);
                                window.location.reload();
                            }}
                        >
                            TRY AGAIN
                        </StyledStagedFailedButton>
                    </Stack>
                </StyledStagedContentContainer>
                <StyledStagedImageContainer className="d-lg-block d-xl-block d-md-none d-sm-none d-none">
                    <StyledStagedImage src={require('../assets/images/failed.png')} fluid />
                </StyledStagedImageContainer>
            </Row>
        </LandingStagedContainer>
    );
};

export default function Landing({ stage }) {
    return (
        <RootContainer fluid>
            <Row fluid>
                <Col lg={12} className="justify-content-center p-0">
                    {!stage && <DefaultLandingComponent />}
                    {stage === 's' && <SuccessLandingComponent />}
                    {stage === 'f' && <FailedLandingComponent />}
                </Col>
            </Row>
        </RootContainer>
    );
}
