import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Stack } from 'react-bootstrap';
import numeral from 'numeral';
import styled from 'styled-components';

const StyledModalTitle = styled(Modal.Title)`
    font-family: Open Sans;
`;

const StyledModalBody = styled(Modal.Body)`
    padding: 3% 8%;
`;

const StyledStack = styled(Stack)`
    justify-content: space-between;
    font-family: Open Sans;
`;

const StyledLabel = styled.label`
    width: 100%;
`;

const StyledLabelContent = styled.p`
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    margin: 0;
`;

const StyledConfirmButton = styled(Button)`
    background: #f2d841;
    color: black;
    border: none;
    font-family: Open Sans;
    padding: 10px 15px;
`;

const StyledCancelButton = styled(Button)`
    border: none;
    font-family: Open Sans;
    padding: 10px 15px;
    margin-right: 10px;
`;

const StyledFooter = styled(Modal.Footer)`
    border: none;
`;

const ConfirmationModal = ({ show, handleClose, plan, handleSubmit }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <StyledModalTitle>Premium Breakdown</StyledModalTitle>
            </Modal.Header>
            <StyledModalBody>
                <StyledStack gap={2} direction="horizontal">
                    <p>Basic Premium</p>
                    <p>{numeral(plan?.basePrem ?? 0).format('0,0.00')}</p>
                </StyledStack>
                <StyledStack gap={2} direction="horizontal">
                    <p>Documentary Stamp Tax</p>
                    <p>{numeral(plan?.dstAmt ?? 0).format('0,0.00')}</p>
                </StyledStack>
                <StyledStack gap={2} direction="horizontal">
                    <p>Value Added Tax</p>
                    <p>{numeral(plan?.vatAmt ?? 0).format('0,0.00')}</p>
                </StyledStack>
                <StyledStack gap={2} direction="horizontal">
                    <p>Local Gov't. Tax</p>
                    <p>{numeral(plan?.lgtAmt ?? 0).format('0,0.00')}</p>
                </StyledStack>
                <hr />
                <StyledStack gap={2} direction="horizontal">
                    <p>Total Amount Due</p>
                    <p>₱ {numeral(plan?.premAmt ?? 0).format('0,0.00')}</p>
                </StyledStack>
                <StyledLabel className="form-check-label">
                    <StyledLabelContent className="d-grid gap-2">
                        *For payment using BPI Debit Mastercard®, please make sure to turn on the e-commerce access in
                        your BPI Online to proceed with the transaction.
                    </StyledLabelContent>
                </StyledLabel>
            </StyledModalBody>
            <StyledFooter>
                <StyledCancelButton variant="secondary" onClick={handleClose}>
                    Cancel
                </StyledCancelButton>
                <StyledConfirmButton variant="primary" onClick={handleSubmit}>
                    Confirm
                </StyledConfirmButton>
            </StyledFooter>
        </Modal>
    );
};

export default ConfirmationModal;
